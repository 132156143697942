<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title" v-if="type == 'edit'">{{ form.type | logformat }}</div>
      <div class="head-title" v-else>{{ titleType | formats }}</div>
      <!-- <el-button type="primary" @click="submitbt">返回</el-button> -->
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="topcontain">
      <div class="text text2">{{ iteminfo.plan_name }}</div>
      <div class="text">实习形式：{{ iteminfo.type | typeformat }}</div>
      <div class="text">
        实习时间：{{ iteminfo.start_date | dataformat }}~{{ iteminfo.end_date | dataformat }}
      </div>
    </div>
    <div class="maincontain">
      <el-form class="elform" ref="form" :model="form" label-width="80px">
        <el-form-item class="elinput" label="标题:">
          <el-input v-model="form.title" placeholder="请输入(20字以内)"></el-input>
        </el-form-item>

        <el-form-item class="elinput" label="总结时间:">
          <template v-if="form.type == 1">
            <el-date-picker class="elinput11" v-model="form.start_date" type="date" placeholder="选择日期">
            </el-date-picker>
            <el-date-picker class="elinput11" v-model="form.end_date" type="date" placeholder="选择日期">
            </el-date-picker>
          </template>
          <template v-else>
            <el-date-picker class="elinput11" v-model="form.start_date" type="date" placeholder="选择日期">
            </el-date-picker>
          </template>
        </el-form-item>
        <el-form-item class="elinput2" label="内容:">
          <div class="text-editor">
            <Tinymce ref="editor" v-model="form.content" style="height: auto; border-radius: 22px" @setInput="getValue">
            </Tinymce>
          </div>
        </el-form-item>
        <el-form-item class="elinput" label="附件:">
          <UploadFiles :module="'practice_student_report,url'" :cloudType="'practiceStudentReport/url'" :files="fileList" @setUrl="getUrl" />
        </el-form-item>

      </el-form>
      <div class="bts">
        <el-button type="info" @click="toback">取消</el-button>
        <el-button type="primary" @click="submitbt">提交</el-button>
        <!-- <div class="bt" @click="toback()">取消</div>
        <div class="bt bt1" @click="submitbt">提交</div> -->
      </div>
    </div>
  </div>
</template>

<script>
const typemap = ["自主报名,集中安排", "自主报名", "集中安排"];
const typeLog = ['编辑日报', '编辑周报', '编辑月报']
const typeLogAdd = ['新增日报', '新增周报', '新增月报']
import { get_report_deatil, getinfopracticestudentreport, addpracticestudentreport, editpracticestudentreport } from "@/api/practicestudentreport";

import Tinymce from "@/components/VueTinymce/index.vue";
import dayjs from "dayjs";
import UploadFiles from '@/components/upload-files'
export default {
  name: "mainpage",
  components: {
    Tinymce, UploadFiles
  },
  filters: {
    typeformat(val) {
      return typemap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    logformat(val) {
      return typeLog[val];
    },
    formats(value) {
      return typeLogAdd[value]
    }
  },
  data() {
    return {
      form: {
        id: '',
        practice_plan_id: "",
        title: "",
        type: "",
        start_date: "",
        end_date: "",
        content: "",
        file: '',
      },
      fileList: [],
      iteminfo: "",
    };
  },
  computed: {
    log_id() {
      return this.$route.query.id
    },
    practice_plan_id() {
      return this.$route.query.practice_plan_id
    },
    student_id() {
      return this.$route.query.student_id
    },
    type() {
      return this.$route.query.type
    },
    titleType() {
      return this.$route.query.titleType
    }
  },
  created() {
    this.form.practice_plan_id = this.practice_plan_id
    this.init_repoet_info()
    if (this.type == 'edit') {
      this.initLogInfo()
    } else {
      this.form.type = this.$route.query.titleType
    }
  },
  methods: {
    init_repoet_info() {
      const params = {
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id
      }
      get_report_deatil(params).then(res => {
        if (res.code == 0) {
          this.iteminfo = res.data
        }
      })
    },
    initLogInfo() {
      const params = {
        id: this.log_id,
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id
      }
      getinfopracticestudentreport(params).then(res => {
        if (res.code == 0) {
          const data = res.data
          this.form = {
            id: data.id,
            practice_plan_id: data.practice_plan_id,
            title: data.title,
            type: data.type,
            start_date: data.start_date ? data.start_date * 1000 : '',
            end_date: data.end_date ? data.end_date * 1000 : '',
            content: data.content,
            file: JSON.stringify(data.file),
          }
          this.fileList = Array.isArray(data.file) ? data.file : []
        }
      })
    },
    toback() {
      this.$router.push({
        path: "/home/personalcenter/log",
        query: {
          practice_plan_id: this.practice_plan_id
        },
      });
    },

    submitbt() {
      if (this.form.type == 1) {
        if (this.form.start_date) {
          this.form.start_date = dayjs(this.form.start_date).unix();
        } else {
          this.form.start_date = "";
        }
        if (this.form.end_date) {
          this.form.end_date = dayjs(this.form.end_date).unix();
        } else {
          this.form.end_date = "";
        }
      } else {
        if (this.form.start_date) {
          this.form.end_date = this.form.start_date = dayjs(
            this.form.start_date
          ).unix();
        } else {
          this.form.start_date = "";
          this.form.end_date = "";
        }
      }
      if (this.type == 'edit') {
        this.edit_report(this.form)
      } else {
        this.addpracticestudentreport(this.form);
      }

    },

    addpracticestudentreport(params) {
      addpracticestudentreport(params)
        .then((response) => {
          if (response.code == 0) {
            this.$message({
              message: "提交成功!",
              type: "success",
            });

            this.toback();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //
    edit_report(params) {
      editpracticestudentreport(params)
        .then((response) => {
          if (response.code == 0) {
            this.$message({
              message: "提交成功!",
              type: "success",
            });

            this.toback();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    // 获取富文本得值
    getValue(data) {
      this.form.content = data;
    },
    getUrl(data) {
      this.form.file = data
    }
  },
};
</script>
<style scoped lang="scss">










.mainpage {
  background: #fff;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
      margin-left: 40px;
      margin-top: 10px;
    }

    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-right: 40px;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .line {
    margin-top: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }

  .topcontain {
    background: #fff;
    height: 100px;
    // margin-top: 20px;
    // margin-bottom: 20px;
    padding: 40px;

    .text {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      color: #222222;
    }

    .text2 {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }

  .maincontain {
    background: #fff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .elform {
      .elinput {
        width: 500px;
      }

      .elinput11 {
        margin-right: 10px;
        width: 200px;
      }

      .elinput2 {
        width: 800px;
      }

      .inputcontain {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bt {
          padding: 0px;
          margin: 0px;
          margin-left: 10px;
          width: 88px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          background: #3d84ff;
          color: #ffffff;
        }
      }

      .file-select-contain {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .file-selecttip {
          margin-left: 10px;
          padding-left: 10px;
          text-align: left;
          width: 200px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
        }

        .file-selectbt {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          width: 121px;
          height: 40px;
          line-height: 40px;
          padding: 0px;
          text-align: center;
          background: #3d84ff;
          border: 1px solid #3d84ff;
          border-radius: 4px;

          &:active {
            background: #3d84ff;
          }
        }
      }
    }

    .bts {
      margin-top: 40px;
      margin-right: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 14px;

        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
      }

      .bt1 {
        background: #3d84ff;
        color: #ffffff;
        border: 1px solid #3d84ff;
      }
    }
  }
}
</style>
